import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class BillingPackagesService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/billing_packages?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  create(id, ot_packages, investigations, name, type, value): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/billing_packages?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          ot_packages,
          investigations,
          name,
          type,
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchBilling(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/billing_packages/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPackages(
    id,
    ot_packages,
    investigations,
    name,
    type,
    value,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/billing_packages/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          ot_packages,
          investigations,
          name,
          type,
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPackagesV2(
    id,
    ot_packages,
    investigations,
    patient,
    type,
    value,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/billing_package_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          id,
          ot_packages,
          investigations,
          patient,
          type,
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllOTServices(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/services/ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&active=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
